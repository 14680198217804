import { refreshTokenApi } from '@/lib/_apis/authentication';
import { httpService } from '@/lib/_apis/httpService';
import {
  clearAllCookies,
  deleteCookie,
  getCookie,
  setCookie,
} from '@/lib/constants/storage';
import { QueryParamEnum } from '@/lib/hooks/useAppRouter';
import { userActions } from '@/lib/redux/slices/userSlice';
import { dispatch } from '@/lib/redux/store';
import { jwtDecode } from 'jwt-decode';

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;

  const isStillValid = decoded.exp > currentTime;

  return isStillValid;
};

const setSession = (accessToken: string | null) => {
  if (!accessToken) {
    deleteCookie(QueryParamEnum.accessToken);
    delete httpService.defaults.headers.common.Authorization;
    return;
  }

  setCookie(QueryParamEnum.accessToken, accessToken);
  httpService.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

const setRefreshToken = (refreshToken: string | null) => {
  if (!refreshToken) {
    deleteCookie(QueryParamEnum.refreshToken);
    return;
  }

  setCookie(QueryParamEnum.refreshToken, refreshToken);
};

// const refreshToken = async () => {
//   const token = getCookie(QueryParamEnum.refreshToken);

//   if (!token) {
//     return;
//   }

//   const { data } = await refreshTokenApi(token);

//   const { accessToken, refreshToken } = data.payload;

//   setSession(accessToken);
//   setRefreshToken(refreshToken);
// };

const clearAuthData = () => {
  setSession(null);
  setRefreshToken(null);

  localStorage.clear();

  clearAllCookies();

  dispatch(userActions.logout());
};

const getValidAccessToken = () => {
  // debugger;
  const accessToken = getCookie(QueryParamEnum.accessToken) || '';

  if (!isValidToken(accessToken)) {
    return '';
  }

  return accessToken;
};

const getValidRefreshToken = () => {
  const refreshToken = getCookie(QueryParamEnum.refreshToken) || '';

  if (!isValidToken(refreshToken)) {
    return '';
  }

  return refreshToken;
};

export {
  clearAuthData,
  isValidToken,
  // refreshToken,
  setRefreshToken,
  setSession,
  getValidAccessToken,
  getValidRefreshToken,
};
