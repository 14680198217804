export type TokenPayload = {
  type: string;
  accessToken: string;
  // expiresIn: string;
  refreshToken: string;
  // expiresAt: string;
};

export type Profile = {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  mobileNumber: string;
  emailVerified: boolean;
  mobileVerified: boolean;
  passwordSet: boolean;
  referralCode: string;
  referredBy?: PartialProfile;
  roles?: (string | PartialRole)[];
  state?: string;
  photo?: string;
  blocked?: boolean;
  fcmToken?: string;
  acceptTerms?: boolean;
  onboarded?: Onboarded;
};

export type SignUpInitSuccess = Profile & {
  createdAt: string;
  updatedAt: string;
};

export type AuthSuccess = {
  user: Profile;
  payload: TokenPayload;
};

export type SetPassword = {
  password: string;
  confirmPassword: string;
};

export type PasswordPayload = Pick<Profile, 'email'> & SetPassword;

export type CreateProfile = Pick<
  Profile,
  'firstName' | 'lastName' | 'email' | 'mobileNumber' | 'acceptTerms'
> & {
  referredBy?: string;
};

export type UpdateProfile = Partial<
  Pick<
    Profile,
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'mobileNumber'
    | 'photo'
    | 'state'
    | 'fcmToken'
  >
>;

export type TSignIn = Pick<Profile, 'email'> & Pick<SetPassword, 'password'>;

export interface UpdatePassword {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export enum PasswordActionEnum {
  setPassword = 'set-password',
  resetPassword = 'reset-password',
  updatePassword = 'update-password',
}

export enum OtpTypeEnum {
  REGISTRATION = 'REGISTRATION',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
}

export enum OtpChannelEnum {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
}

export type SendOtp = {
  type: OtpTypeEnum;
  channel: OtpChannelEnum;
  value: string;
};

export type VerifyOtp = SendOtp & {
  code: string;
};

export type ApiRoute = {
  path: string;
  method: 'get' | 'post' | 'patch';
};

export type Role = {
  id: string;
  name: string;
  description: string;
  operations: string[];
  apiRoutes: ApiRoute[];
};

export type RolePayload = Omit<Role, 'id'>;

export type PartialRole = Pick<Role, 'id' | 'operations'>;

export type PartialProfile = Pick<
  Profile,
  'id' | 'fullName' | 'photo' | 'email'
>;

export type OnboardedKey = 'business' | 'storefront' | 'products';

export type Onboarded = Record<OnboardedKey, boolean>;

export enum OnboardedEnum {
  BUSINESS = 'business',
  STOREFRONT = 'storefront',
  PRODUCTS = 'products',
}
