import { httpService } from '@/lib/_apis/httpService';
import { HttpSuccessResponse } from '@/lib/_types/ApiResponse';
import {
  SendOtp,
  VerifyOtp,
  CreateProfile,
  AuthSuccess,
  Profile,
  SetPassword,
  UpdatePassword,
  UpdateProfile,
  RolePayload,
  Role,
  ApiRoute,
  SignUpInitSuccess,
  PasswordPayload,
} from '@/lib/_types/Authentication';

export const sendOtpApi = async (
  body: SendOtp
): Promise<HttpSuccessResponse> => {
  return httpService.post('auth/otp/send', body);
};

export const verifyOtpApi = async (
  body: VerifyOtp
): Promise<HttpSuccessResponse> => {
  return httpService.patch('auth/otp/verify', body);
};

export const signUpApi = async (
  payload: CreateProfile
): Promise<HttpSuccessResponse<SignUpInitSuccess>> => {
  return httpService.post('auth/register', payload);
};

export const signInApi = async (
  email: Profile['email'],
  password: string
): Promise<HttpSuccessResponse<AuthSuccess>> => {
  return httpService.post('auth/login', { email, password });
};

export const setPasswordApi = async ({
  email,
  password,
  confirmPassword,
}: PasswordPayload): Promise<HttpSuccessResponse<AuthSuccess>> => {
  return httpService.post('auth/set-password', {
    email,
    password,
    confirmPassword,
  });
};

export const changePasswordApi = async ({
  email,
  password,
  confirmPassword,
}: PasswordPayload): Promise<HttpSuccessResponse> => {
  return httpService.patch('auth/change-password', {
    email,
    password,
    confirmPassword,
  });
};

export const updatePasswordApi = async (
  payload: UpdatePassword
): Promise<HttpSuccessResponse> => {
  return httpService.patch('auth/update-password', payload);
};

export const refreshTokenApi = async (
  refreshToken: string
): Promise<HttpSuccessResponse<Pick<AuthSuccess, 'payload'>>> => {
  return httpService.post('auth/refresh', { refreshToken });
};

export const getProfileApi = async (): Promise<
  HttpSuccessResponse<Profile>
> => {
  console.count('getProfileApi');
  return httpService.get('auth/me');
};

export const updateProfileApi = async (
  payload: UpdateProfile
): Promise<HttpSuccessResponse<Profile>> => {
  return httpService.patch('auth/me', payload);
};

export const logoutApi = async (): Promise<HttpSuccessResponse> => {
  return httpService.post('auth/logout');
};

// ROLE MANAGEMENT

export const createRoleApi = async (
  body: RolePayload
): Promise<HttpSuccessResponse<Role>> => {
  return httpService.post(`admin/roles`, body);
};

export const updateRoleApi = async (
  id: Role['id'],
  body: RolePayload
): Promise<HttpSuccessResponse<Role>> => {
  return httpService.patch(`admin/roles/${id}`, body);
};

export const getRolesApi = async (): Promise<HttpSuccessResponse<Role[]>> => {
  return httpService.get(`admin/roles`);
};

export const getApiRoutesApi = async (): Promise<
  HttpSuccessResponse<ApiRoute[]>
> => {
  return httpService.get(`admin/roles/routes`);
};

export const getRoleApi = async (
  id: Role['id']
): Promise<HttpSuccessResponse<Role>> => {
  return httpService.get(`admin/roles/${id}`);
};

export const assignRolesApi = async (id: string, roles: string[]) => {
  return httpService.patch(`admin/roles/user/${id}/assign`, { roles });
};

export const removeRolesApi = async (id: string, roles: string[]) => {
  return httpService.patch(`admin/roles/user/${id}/remove`, { roles });
};

export const checkEmailApi = async (
  email: string
): Promise<HttpSuccessResponse<boolean>> => {
  return httpService.post(`auth/check-email`, { email });
};

export const checkMobileNumberApi = async (
  mobileNumber: string
): Promise<HttpSuccessResponse<boolean>> => {
  return httpService.post(`auth/check-mobile-number`, { mobileNumber });
};

export const checkReferralCodeApi = async (
  referralCode: string
): Promise<HttpSuccessResponse<boolean>> => {
  return httpService.post(`auth/check-referral-code`, { referralCode });
};
