'use client';
import {
  getProfileApi,
  logoutApi,
  setPasswordApi,
  signInApi,
  signUpApi,
} from '@/lib/_apis/authentication';
import { HttpErrorResponse } from '@/lib/_types/ApiResponse';
import {
  clearAuthData,
  getValidAccessToken,
  setRefreshToken,
  setSession,
} from '@/lib/constants/jwt';
import { ROUTES } from '@/lib/constants/routes';
import {
  MutableRefObject,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import {
  AuthSuccess,
  CreateProfile,
  PasswordActionEnum,
  PasswordPayload,
  SignUpInitSuccess,
} from '@/lib/_types/Authentication';
import { dispatch, RootState, useSelector } from '@/lib/redux/store';
import { checkRole, userActions } from '@/lib/redux/slices/userSlice';
import { QueryParamEnum, useAppRouter } from '@/lib/hooks/useAppRouter';
import { deleteCookie, setCookie } from '@/lib/constants/storage';

type Providers = 'google';

type ContextState = {
  // profile: Profile | null;
  login: (
    email: string,
    password: string,
    successCallback?: (authResponse: AuthSuccess) => void
  ) => void;
  initOauthLogin: (provider: Providers) => void;
  oauthLogin: (
    accessToken: string,
    refreshToken: string,
    successCallback?: (authResponse: AuthSuccess) => void
  ) => void;
  logout: () => void;
  initSignup: (
    payload: CreateProfile,
    successCallBack?: (data: SignUpInitSuccess) => void
  ) => void;
  signup: (
    payload: PasswordPayload,
    successCallback?: (authResponse: AuthSuccess) => void
  ) => void;
  // isSuperAdmin: boolean;
  // isAdmin: boolean;
  loggingYouIn: MutableRefObject<boolean>;
  resolved: boolean;
  toSetPassword: () => void;
  // resolved: boolean;
};

const AuthContext = createContext<ContextState>({
  login: () => undefined,
  initOauthLogin: () => undefined,
  oauthLogin: () => undefined,
  logout: () => undefined,
  initSignup: () => undefined,
  signup: () => undefined,
  loggingYouIn: { current: false },
  resolved: false,
  toSetPassword: () => undefined,
});

type Props = {
  children: ReactNode;
};

export const AuthContextProvider = ({ children }: Props) => {
  const { profile } = useSelector((state: RootState) => state.userSlice);

  const { router, setQueryParam, routeWithQueryParams } = useAppRouter();

  const loggingYouIn = useRef(false);
  const [resolved, setResolved] = useState(false);

  // const { token, notificationPermissionStatus } = useFcmToken();

  // const isLoggedIn = useMemo(() => !!profile, [profile]);

  const nextUrl = useMemo(() => {
    //TODO: i hope this is not a bug, i hope it truly resolves the value
    if (typeof localStorage === 'undefined') {
      return '';
    }
    return localStorage.getItem(QueryParamEnum.nextUrl) || '';
  }, []);

  const toSetPassword = useCallback(() => {
    // debugger;

    setCookie(QueryParamEnum.action, PasswordActionEnum.setPassword);

    routeWithQueryParams(ROUTES.managePassword(), {
      [QueryParamEnum.action]: PasswordActionEnum.setPassword,
      [QueryParamEnum.accessToken]: undefined,
      [QueryParamEnum.refreshToken]: undefined,
      [QueryParamEnum.provider]: undefined,
    });
  }, [routeWithQueryParams]);

  const doLoginActions = async (
    data: AuthSuccess,
    successCallBack?: (authSuccess: AuthSuccess) => void
  ) => {
    // debugger;
    if (loggingYouIn.current) {
      return;
    }

    loggingYouIn.current = true;
    const { user, payload } = data;
    const { accessToken, refreshToken } = payload;

    console.log(user);

    setSession(accessToken);
    setRefreshToken(refreshToken);

    dispatch(userActions.setProfile(user));

    // toast.success('Logging you in!');

    if (successCallBack) {
      successCallBack(data);
      // loggingYouIn.current = false;
      return;
    }

    if (!user?.passwordSet) {
      toSetPassword();
      return;
    }

    toast.success('Logging you in!');

    const isAdmin = checkRole(user.roles, 'ADMIN');

    const dashboardUrl = user.onboarded?.business
      ? ROUTES.dashboard
      : ROUTES.onboarding();

    const url = nextUrl || (isAdmin ? ROUTES.console : dashboardUrl);

    router.replace(url);

    // loggingYouIn.current = false;
  };

  const loginHandler = async (
    email: string,
    password: string,
    successCallBack?: (authSuccess: AuthSuccess) => void
  ) => {
    try {
      const { data } = await signInApi(email, password);

      await doLoginActions(data, successCallBack);
    } catch (error) {
      toast.error(
        (error as HttpErrorResponse)?.message ||
          'Failed to log you in, please try again later or contact admin'
      );
    }
  };

  const initSignupHandler = async (
    payload: CreateProfile,
    successCallBack?: (data: SignUpInitSuccess) => void
  ) => {
    try {
      const { data } = await signUpApi(payload);

      const { createdAt, updatedAt, ...user } = data;

      dispatch(userActions.setProfile(user));

      setQueryParam(QueryParamEnum.showModal, true);

      successCallBack && successCallBack(data);
    } catch (error) {
      toast.error(
        (error as HttpErrorResponse)?.message ||
          'Failed to start account creation process, please try again later or contact admin'
      );
    }
  };

  const signupHandler = async (
    payload: PasswordPayload,
    successCallBack?: (authSuccess: AuthSuccess) => void
  ) => {
    try {
      const { data } = await setPasswordApi(payload);

      toast.success('Password created successfully');

      loggingYouIn.current = false;

      deleteCookie(QueryParamEnum.action);

      await doLoginActions(data, successCallBack);
    } catch (error) {
      toast.error(
        (error as HttpErrorResponse)?.message ||
          'Failed to create password and finish account setup, please try again later or contact admin'
      );
    }
  };

  const initOauthLoginHandler = (provider: Providers) => {
    const apiBaseUrl = process.env.NEXT_PUBLIC_API_URL;
    // const currentUrl = window.location.href;
    window.location.href = `${apiBaseUrl}/auth/${provider}`;
  };

  const oauthLoginHandler = async (
    accessToken: string,
    refreshToken: string,
    successCallBack?: (authSuccess: AuthSuccess) => void
  ) => {
    setSession(accessToken);
    setRefreshToken(refreshToken as string);

    const { data: user } = await getProfileApi();

    await doLoginActions(
      {
        user,
        payload: {
          accessToken,
          refreshToken,
          type: 'bearer',
        },
      },
      successCallBack
    );
  };

  const logoutHandler = () => {
    logoutApi()
      .then()
      .catch(console.error)
      .finally(() => {
        clearAuthData();
        router.replace(ROUTES.auth);
      });
  };

  // useEffect(() => {
  //   // if (resolved) {
  //   //   return;
  //   // }

  //   // resolved.current = false;

  //   getProfileApi()
  //     .then(({ data = null }) => {
  //       dispatch(userActions.setProfile(data));
  //     })
  //     .finally(() => {
  //       setResolved(false);
  //     });
  // }, []);

  // useEffect(() => {
  //   if (!token || notificationPermissionStatus !== 'granted' || !isLoggedIn) {
  //     return;
  //   }

  //   updateProfileApi({ fcmToken: token })
  //     .then(() => {
  //       console.log('fcm token updated');
  //     })
  //     .catch(console.error);
  // }, [isLoggedIn, notificationPermissionStatus, token]);

  const contextValue = {
    login: loginHandler,
    initOauthLogin: initOauthLoginHandler,
    oauthLogin: oauthLoginHandler,
    logout: logoutHandler,
    initSignup: initSignupHandler,
    signup: signupHandler,
    loggingYouIn,
    toSetPassword,
    resolved,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
