'use client';
import { refreshTokenApi } from '@/lib/_apis/authentication';
import { HttpErrorResponse } from '@/lib/_types/ApiResponse';
import {
  clearAuthData,
  isValidToken,
  setRefreshToken,
  setSession,
} from '@/lib/constants/jwt';
import { ROUTES } from '@/lib/constants/routes';
import { getCookie } from '@/lib/constants/storage';
import { QueryParamEnum } from '@/lib/hooks/useAppRouter';
import axios, { AxiosError, AxiosRequestConfig, HttpStatusCode } from 'axios';
import { useRouter } from 'next/navigation';

const baseURL = process.env.NEXT_PUBLIC_API_URL;

//TODO: handle logout action here
interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

export const httpService = axios.create({ baseURL });

const useNavigateToAuth = () => {
  const router = useRouter();
  return () => {
    router.push(ROUTES.auth);
  };
};

httpService.interceptors.request.use(
  async (config) => {
    const Authorization = config.headers.Authorization;

    if (Authorization) {
      return config;
    }

    const token = getCookie(QueryParamEnum.accessToken);

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpService.interceptors.response.use(
  (response) => {
    return response?.data;
  },
  async (error: AxiosError<HttpErrorResponse>) => {
    const originalRequest = error.config as ExtendedAxiosRequestConfig;
    const status = error?.response?.status;
    const url = error?.config?.url || '';
    let pathname = window?.location?.pathname;
    if (pathname !== ROUTES.home && pathname.endsWith('/')) {
      pathname = pathname.slice(0, -1);
    }

    const excludedApis = ['auth/login'];

    const excludedPathnames = [
      // ROUTES.home,
      ROUTES.auth,
      ROUTES.signUp(),
      ROUTES.managePassword(),
      ROUTES.privacyPolicy,
      ROUTES.termsOfUse,
    ];

    const shouldExclude =
      excludedApis.includes(url) || excludedPathnames.includes(pathname);

    if (
      status === HttpStatusCode.Unauthorized &&
      !originalRequest._retry &&
      !shouldExclude
    ) {
      originalRequest._retry = true;

      try {
        const token = getCookie(QueryParamEnum.refreshToken);

        if (!token) {
          throw new Error();
        }

        const valid = isValidToken(token);

        if (!valid) {
          throw new Error();
        }

        const { data } = await refreshTokenApi(token);

        const { accessToken, refreshToken } = data.payload;

        setSession(accessToken);
        setRefreshToken(refreshToken);

        return httpService(originalRequest);
      } catch (error) {
        clearAuthData();

        return Promise.reject((error as any)?.response?.data);
      }
    }

    if (status === HttpStatusCode.Unauthorized && !shouldExclude) {
      clearAuthData();

      const navigateToAuth = useNavigateToAuth();
      navigateToAuth();

      return Promise.reject(error?.response?.data);
    }

    return Promise.reject(error?.response?.data);
  }
);
